import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const componentName = 'burger-menu-button';

type BurgerMenuButtonProps = BaseProps & {
    onClick: () => void;
    isBurgerMenuOpen: boolean;
};

const BurgerMenuButton = ({ onClick, isBurgerMenuOpen }: BurgerMenuButtonProps) => {
    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const isMobileOrTablet = device === 'mobile' || device === 'tablet';

    const openIcon = isMobileOrTablet ? 'BurgerMenu' : 'CustomBurgerMenu';
    const closeIcon = isMobileOrTablet ? 'HamburgerClose' : 'CustomBurgerClose';

    return (
        <div className={componentName} onClick={onClick}>
            <Icon name={isBurgerMenuOpen ? closeIcon : openIcon} />
            <span className={`${componentName}__text`}>
                <FormattedMessage id="mainNavigation.burgerMenuButtonText" />
            </span>
        </div>
    );
};

export default injectComponent(
    'pattern.organism.MainNavigationCustom.BurgerMenu.BurgerMenuButton',
    BurgerMenuButton,
    'powerxl'
);
