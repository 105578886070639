import { ReactComponent as ArrowLeft } from './assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './assets/icons/arrow-right.svg';
import { ReactComponent as ArrowDownFilled } from './assets/icons/arrow-down-filled.svg';
import { ReactComponent as Trash } from './assets/icons/trash.svg';
import { ReactComponent as Pencil } from './assets/icons/pencil.svg';
import { ReactComponent as Share } from './assets/icons/share.svg';
import { ReactComponent as AddToCart } from './assets/icons/add-to-cart.svg';
import { ReactComponent as Hamburger } from './assets/icons/hamburger.svg';
import { ReactComponent as HamburgerClose } from './assets/icons/hamburger-close.svg';
import { ReactComponent as AccountVerticalBrand } from './assets/icons/account.svg';
import { ReactComponent as AccountFilledVerticalBrand } from './assets/icons/account-filled.svg';
import { ReactComponent as CartVerticalBrand } from './assets/icons/cart.svg';

import catalogBaseIcons from '../catalog-base/icons';

export default {
    Account: AccountVerticalBrand,
    AccountFilled: AccountFilledVerticalBrand,
    AccountVerticalBrand,
    AccountFilledVerticalBrand,
    AddToCart,
    ArrowDown: ArrowDownFilled,
    ArrowDownFilled,
    ArrowLeft,
    ArrowRight,
    BurgerMenu: Hamburger,
    BurgerMenuClose: HamburgerClose,
    Cart: CartVerticalBrand,
    CartVerticalBrand,
    CustomBurgerMenu: catalogBaseIcons.BurgerMenuOpen,
    CustomBurgerClose: catalogBaseIcons.BurgerMenuClose,
    HamburgerClose,
    Pencil,
    Share,
    Trash,
};
